@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}




body {
  scroll-behavior: smooth;
  background-color: rgba(#990931);
  overflow-x: hidden;
}

ion-icon {
  font-size: 35px;
}


/* For small phones: */
@media only screen and (max-width: 600px) {
  .image-class{
    width: 15vh;
    height: 50vh;
    margin: 5px;
    margin-top: 10px;
    padding: 5px;
    
  }
}

/* For tablets: */
@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .image-class {
    width: 22vh;
    height: 60vh;
    margin: 13vh;
    
    padding: 10px;
    
  }
}

/* For desktops: */
@media only screen and (min-width: 1025px) {
  .image-class{
    width: 18vh;
    height: 70vh;
    margin: 2vh;
    margin-top: 45px;
    padding: 15px;
  }
}

/* chatbot container */

.chatbot-container {
  max-width: 400px;
  max-height: 600px;
  position: fixed;
  bottom: 50px;
  right: 50px;
  overflow: auto;
}

/* map container  @media */

@media (max-width: 600px) {
  .map-container {
    height: 50vh;
  }
}
@media (min-width: 601px) and (max-width: 1024px) {
  .map-container {
    height: 75vh;
  }
}
@media (min-width: 1025px) {
  .map-container {
    height: 100vh;
  }
}

.icon-image{
  width: 190px;
  height: 110px;
}

@keyframes shimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

.loading {
  animation-duration: 1.25s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: shimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;
  position: relative;
}

.label{
  width: 11rem; 
  height: 14rem; 
  margin-top: 2.5rem; 
  margin-bottom: 0.75rem;
}



.bottle{
  width: 12rem; 
  height: 39rem;
  margin-top: 2.5rem; 
  margin: 0.75rem; 
 
}

.logo {
  width: 20rem;
  height: 10rem;
}

/* For devices with a maximum width of 1200px */
@media only screen and (max-width: 1200px) {
  .logo {
      width: 18rem;
      height: 10rem;
  }
}
/* For devices with a maximum width of 800px */
@media only screen and (max-width: 800px) {
  .logo {
      width: 15rem;
      height: 10rem;
  }
}
/* For devices with a maximum width of 600px */
@media only screen and (max-width: 600px) {
  .logo {
      width: 10.2rem;
      height: 9rem;
     
  }
}

